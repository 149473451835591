// import logo from './logo.png'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={require('./logo.png')} className="App-logo" alt="logo" />
        <p>
          We're under construction. Please check back for an update by the end of this year.
        </p>
        <a
          className="App-link"
          href="https://www.linkedin.com/in/cyrusbayat/"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>
      </header>
    </div>
  );
}

export default App;
